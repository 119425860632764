const menu = [
  {
    title: "本堂繳費管理",
    action: "mdi-home-variant",
    link: "/innerSearch",
  },
  {
    title: "外堂繳費管理",
    action: "mdi-home-variant-outline",
    link: "/outerSearch",
  },
  {
    title: "活動帳務管理",
    action: "mdi-ticket-account",
    link: "/serialNumberSearch",
  },
  // {
  //   title: "系統帳號管理",
  //   action: "mdi-shield-account-outline",
  //   link: "/account",
  // },
];

export { menu };
